@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../src/assets/font/Pretendard-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: 'Pretendard', Verdana, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif !important;
}

a {
  text-decoration: none;
  color: inherit;
}

:where(.css-byeoj0).ant-drawer {
  z-index: 9999;
}

.ant-drawer.ant-drawer-right.ant-drawer-open {
  z-index: 9999;
}

.swal2-container .swal2-popup {
  padding-bottom: 3em;
  border-radius: 30px;
}

.swal2-modal .swal2-title {
  color: #121621;
  text-align: center;
  font-family: 'Pretendard';
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}

.swal2-modal .swal2-html-container {
  color: #6c7486;
  text-align: center;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 600;
}
.swal2-modal .swal2-actions {
  width: 100%;
  padding: 0 40px;
  gap: 24px;
}
.swal2-modal .alert-btn {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  border: 0;
  border-radius: 10px;
  color: #fff;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
}
.alert-btn-confirm {
  background: #643bdc;
}
.swal2-modal .alert-btn-cancel {
  background: #6c7486;
}

@media screen and (max-width: 640px) {
  .swal2-popup {
    width: 27em;
  }

  .swal2-modal .swal2-title {
    font-size: 18px;
  }

  .swal2-modal .swal2-html-container {
    font-size: 14px;
  }

  .swal2-modal .swal2-actions {
    padding: 0 30px;
    gap: 14px;
  }

  .swal2-modal .alert-btn {
    padding: 10px 0;
    font-size: 14px;
  }
}
