.ql-custom .ql-editor {
  min-height: 300px;
}

.ql-custom .ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-custom .ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
